<template>
    <div>
        404页面
    </div>
</template>
<script>
export default {
    
}
</script>
<style scoped>

</style>